import React from "react"

import Button from "../components/button"
import SectionHeader from "./section-header"

const CallToAction = () => (
  <div style={{ padding: "4rem 6rem", textAlign: "center" }}>
    <SectionHeader
      title="Get Info"
      description="Please contact us with comments, questions, requests, or feedback of any kind. We welcome ideas that would make PicWords better for you!"
    />
    <a href="mailto:contact@picwords.app">
      <Button>Contact</Button>
    </a>
  </div>
)

export default CallToAction
