import React from "react"
import { Row, Col, Media } from "react-bootstrap"

import selectionFeature from "../images/picwords-selection-sketch.png"
import audioFeature from "../images/picwords-audio-sketch.png"
import settingsFeature from "../images/picwords-settings.png"
import SectionHeader from "./section-header"
import { COLORS } from "../styles/constants"

const Content = () => (
  <div>
    <Row>
      <Col lg={{ span: 8, offset: 2 }} style={{ marginBottom: "3rem" }}> 
        <SectionHeader
          title="Features"
          description="PicWords is a simple Augmentative and Alternative Communication app, also known as a Picture Exchange Communication System (PECS/AAC). The goal of PicWords is to help a person with physical and communicative disabilities convey needs and wants."
        />
      </Col>
    </Row>
    <Row>
    <Col sm="6" className="align-self-center order-sm-12">
        <div style={{ textAlign: "center" }}>
          <h3>Fully customizable selections</h3>
          <p style={{ color: COLORS.gray }}>
            Items created can be completely determined by the user.  This way, the person using the app will see familiar, accustomed objects and places, rather than hand drawn or generic images.
        </p>
        </div>
      </Col>
      <Col sm="6" className="order-sm-1" style={{ marginBottom: "3rem" }}>
        <Media>
          <img class="img-fluid" src={settingsFeature} alt="a blank card floating over colorful graphics" />
        </Media>
      </Col>      
    </Row>
    <Row>
    <Col sm="6" className="align-self-center">      
        <div>
          <h3>Create your own image library</h3>
          <p style={{ color: COLORS.gray }}>
            Images can be created by taking pictures in the app or by using any photo in the iPad’s photo library.
        </p>
        </div>
      </Col>
      <Col sm="6" style={{ marginBottom: "3rem" }}>
        <Media>
          <img class="img-fluid" src={selectionFeature} alt="a blank card floating over colorful graphics" />
        </Media>
      </Col>
    </Row>
    <Row>
    <Col sm="6" className="align-self-center order-sm-12">
        <div>
          <h3>Record your own audio</h3>
          <p style={{ color: COLORS.gray }}>
            Audio is easily recorded with the built in mic. Re-record audio or change pictures at any time by editing existing items.
        </p>
        </div>
      </Col>
      <Col sm="6"  className="align-self-center order-sm-1"  style={{ marginBottom: "3rem" }}>
        <Media>
          <img class="img-fluid" src={audioFeature} alt="a blank card floating over colorful graphics" />
        </Media>
      </Col>
    </Row>
  </div>
)

export default Content
