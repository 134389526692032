import React from "react"
import PropTypes from "prop-types"

import headerImage from "../images/header.png"
import { Media } from "react-bootstrap"
import heroImage from "../images/picwords-ipad-sketch.png"

const Header = ({ siteTitle }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      paddingTop: "4rem",
    }}
  >
    <div
      style={{
        backgroundImage: `url(${headerImage})`,
        position: "absolute",
        top: 0,
        zIndex: -5,
        height: "100vh",
        width: "90vw",
        opacity: 0.5,
      }}
    />
    <h1 style={{ textAlign: "center" }}>PicWords</h1>
    <p style={{ textAlign: "center", maxWidth: 440 }}>
      PicWords is back in development! A new version will be coming soon!
    </p>
    <div style={{ margin: 0, position: "relative" }}>
      <div style={{ clipPath: "inset(1% 2.5% round 1% 2.5%)" }}>
        <Media>
          <img src={heroImage} alt="Picwords is returning hero" class="img-fluid" />
        </Media>
      </div>
    </div>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
